import styled from 'styled-components';

const Container = styled.div`
  padding: 32px;
`;

const Title = styled.h1`
  color: var(--primary);
  font-size: 48px;
  font-weight: 500;
`;

export const App = (): JSX.Element => {
  return (
    <Container>
      <Title>ListKite</Title>
      <p>Collect &amp; manage contact details and mailing lists on web, iOS, and Android</p>
    </Container>
  );
};
